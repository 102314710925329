import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

type ResponseType = 'json' | 'text' | 'blob' | 'arraybuffer';
type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';


// ** EL TOKEN SE ENVÍA INICIALMENTE EN EL "SERVICIO TOKEN-INTERCEPTOR.SERVICE.TS" ----- *


@Injectable({
  providedIn: 'root',
})
export class HttpClientProvider {

  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;

  constructor(
    public http: HttpClient,
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();
    console.log('Hello HttpClientProvider Provider');
  }

  getRequest<T>(endpoint: string, responseType: ResponseType = 'json', params: any = {}): Observable<any> {
    this.isLoadingSubject.next(true);

    const options: any = {
      responseType,
      params: new HttpParams({ fromObject: params })
    };

    return this.http.get<T>(endpoint, options).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  postRequest<T>(endpoint: string, body: any, responseType: ResponseType = 'json', params: any = {}) : Observable<any>{
    this.isLoadingSubject.next(true);

    const options: any = {
      responseType,
      params: new HttpParams({ fromObject: params })
    };

    return this.http.post<T>(endpoint, body, options).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  putRequest<T>(endpoint: string, body: any, responseType: ResponseType = 'json', params: any = {}) : Observable<any>{
    this.isLoadingSubject.next(true);

    const options: any = {
      responseType,
      params: new HttpParams({ fromObject: params })
    };

    return this.http.put<T>(endpoint, body, options).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  deleteRequest(endpoint: string) : Observable<any>{
    this.isLoadingSubject.next(true);
    return this.http.delete(endpoint).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  customRequest(method: HttpMethod, endpoint: string, body?: any, responseType: ResponseType = 'json', params: any = {}): Observable<any> {
    this.isLoadingSubject.next(true);

    const options: any = {
      responseType,
      params: new HttpParams({ fromObject: params })
    };

    let request: Observable<any>;

    switch (method) {
      case 'GET':
        request = this.http.get(endpoint, { ...options, responseType: responseType as 'json' });
        break;
      case 'POST':
        request = this.http.post(endpoint, body, { ...options, responseType: responseType as 'json' });
        break;
      case 'PUT':
        request = this.http.put(endpoint, body, { ...options, responseType: responseType as 'json' });
        break;
      case 'DELETE':
        request = this.http.delete(endpoint, { ...options, responseType: responseType as 'json' });
        break;
      default:
        throw new Error(`Unsupported request method: ${method}`);
    }

    return request.pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

}
