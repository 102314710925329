import { Injectable } from '@angular/core';
import { getEnvironment } from '../environment/environment';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  env = getEnvironment();

  private isLoading = false;
  private isLoadingAlt = false;
  constructor(
  ) { }

  dismiss() {
    if(this.isLoading) {
      $('html, body').css('overflow', '');
      $('.loading_wrapper.init').remove();
      this.isLoading = false;
    }
  }

  loadingShow(typeLoader: 1 | 2 | 3 = this.env.loaderType, message?: string) {
    if(!this.isLoading) {
      // console.log('loading');
      let default_loader = '<div class="loads-ellipsis"><div></div><div></div><div></div><div></div></div>';
      let loader;
      switch (typeLoader) {
        case 3:
          loader = `<div class="w-full"><img src="${this.env.imgLogo}" /><br></div><span class="loader__spin3"></span>`;
          break;

        case 2:
          loader = '<div class="loadingio-spinner-dual-ball"><div class="ldio-dualball"><div></div><div></div><div></div></div></div>';
          break;
      
        default:
          loader = default_loader;
          break;
      }
      $('html, body').css('overflow', 'hidden');
      $('body').append(`<div class="loading_wrapper init"><div class="backdrop_loading"></div><div class="loading__content">${loader}<p>${message || this.env.loaderDefaultText}</p></div></div>`);
      this.isLoading = true;
      // setTimeout(() => {
      //   if(this.isLoading) {
      //     $('.loading_wrapper.init').remove();
      //     this.isLoading = false;
      //   }
      // }, 10000);
    }
  }

  dismissAlt() {
    if(this.isLoadingAlt) {
      $('html, body').css('overflow', '');
      $('.loading_wrapper.alt').remove();
      this.isLoadingAlt = false;
    }
  }

  loadingShowAlt(typeLoader: 1 | 2 | 3 = this.env.loaderType, message?: string) {
    if(!this.isLoading) {
      // console.log('loading');
      let default_loader = '<div class="loads-ellipsis"><div></div><div></div><div></div><div></div></div>';
      let loader;
      switch (typeLoader) {
        case 3:
          loader = '<span class="loader__spin3"></span>';
          break;

        case 2:
          loader = '<div class="loadingio-spinner-dual-ball"><div class="ldio-dualball"><div></div><div></div><div></div></div></div>';
          break;
      
        default:
          loader = default_loader;
          break;
      }
      $('html, body').css('overflow', 'hidden');
      $('body').append(`<div class="loading_wrapper alt"><div class="backdrop_loading"></div><div class="loading__content">${loader}<p>${message || this.env.loaderDefaultText}</p></div></div>`);
      this.isLoadingAlt = true;
      // setTimeout(() => {
      //   if(this.isLoadingAlt) {
      //     $('.loading_wrapper.alt').remove();
      //     this.isLoadingAlt = false;
      //   }
      // }, 10000);
    }
  }
}
